import React from 'react'
import './toc.scss'

class TOC extends React.Component {
  constructor(props) {
    super(props);

    const headings = this.props.headings.items;

    this.state = {
      tocOpen: true
    }

    this.toggleToc = this.toggleToc.bind(this);

    const createTocItem = (headings) =>
      headings.map(heading => (
        <li key={heading.url}>
          <a href={heading.url} dangerouslySetInnerHTML={{ __html: heading.title }} />
          {
            heading.items ? (
              <ol>{createTocItem(heading.items)}</ol>
            ) : ''
          }
        </li>
      ))

    this.tocItem = createTocItem(headings);
  }

  toggleToc() {
    this.setState({ tocOpen: !this.state.tocOpen });
  }

  render() {
    return (
      <div className={`toc ${this.state.tocOpen ? 'toc_is-open' : ''}`}>
        <div className="toc__title">
          <button className="toc__toggle" onClick={this.toggleToc} aria-haspopup="true" aria-controls="toc" aria-expanded={this.state.tocOpen ? true : false}>
            目次<span className="screen-reader-text">目次を開閉</span>
          </button>
        </div>
        <div className="toc__content" id="toc" style={this.state.tocOpen ? { display: 'block' } : { display: 'none' }}>
          <ol>{this.tocItem}</ol>
        </div>
      </div>
    )
  }
}

export default TOC;
