import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './share.scss'
import Icon from './icon.js'
import Content from '../layout/content.js'

const Share = ({ title, pathname }) => {

  let [copied, toggleCopied] = useState(false);

  function copy(e, value) {
    document.addEventListener(
      'copy',
      e => {
        e.preventDefault();
        e.clipboardData.setData('text/plain', value);
      },
      { once: true }
    );
    document.execCommand('copy');
    toggleCopied(true);

    setTimeout(() => toggleCopied(false), 3000);
  }


  const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    defaultTitle: title
                    siteUrl
                }
            }
        }
    `)

  const pageData = {
    title: title.replace(/ /g, '%20') || data.site.siteMetadata.defaultTitle.replace(/ /g, '%20'),
    url: `${data.site.siteMetadata.siteUrl}${pathname || '/'}`,
  }

  const socialItemsSmall = {
    facebook: {
      name: 'Facebook',
      url: `http://www.facebook.com/share.php?u=${pageData.url}`
    },
    twitter: {
      name: 'Twitter',
      url: `https://twitter.com/share?url=${pageData.url}&via=nana_winter_web&related=nana_winter_web&text=${pageData.title}`
    },
    pocket: {
      name: 'Pocket',
      url: `http://getpocket.com/edit?url=${pageData.url}&title=${pageData.title}`
    },
    line: {
      name: 'LINE',
      url: `http://line.me/R/msg/text/?${pageData.title}%20${pageData.url}`
    },
  }

  let shareButtonsSmall = [];

  Object.keys(socialItemsSmall).forEach(key => {
    shareButtonsSmall.push(
      <li className="share__item" key={key}>
        <a href={socialItemsSmall[key].url} rel="noopener noreferrer" target="_blank" className={`share__link share__link_${key}`} lang="en">
          <span className="share__icon-area">
            <Icon type={key} className="share__icon" />
          </span>
          <span className="share__text">
            {socialItemsSmall[key].name}
          </span>
        </a>
      </li>
    )
  });

  return (
    <Content.Item className="Share" section>
      <h2 className="title title_main">シェア</h2>
      <ul className="share__list share__list_small-button">
        {shareButtonsSmall}
      </ul>
      <ul className="share__list share__list_large-button">
        <li className="share__item">
          <button type="button" className={`share__link share__link_copy ${copied ? 'share__link_copied' : ''}`} onClick={(e) => copy(e, pageData.title.replace(/%20/g, ' ') + ' ' + pageData.url)}>
            <span className="share__icon-area">
              <Icon type="clipboard" className="share__icon share__icon_not-yet" />
              <Icon type="done" className="share__icon share__icon_done" />
            </span>
            <span className="share__text">
              記事タイトルと<span lang="en">URL</span>をコピー
            </span>
          </button>
          <span className="screen-reader-text" aria-hidden={copied ? false : true} style={{ display: copied ? 'block' : 'none' }}>コピーしました</span>
        </li>
        <li className="share__item">
          <a href={`https://feedly.com/i/subscription/feed/${data.site.siteMetadata.siteUrl}/feed/index.xml`} target='blank' rel="noopener noreferrer"
            className="share__link share__link_feedly" lang="en">
            <span className="share__icon-area">
              <Icon type="feedly" className="share__icon" />
            </span>
            <span className="share__text">
              Feedly
            </span>
          </a>
        </li>
      </ul>
    </Content.Item>
  )
}

export default Share;
