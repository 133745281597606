import React from 'react'

class CopyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false
    }

    this.copyCode = this.copyCode.bind(this);
  }

  copyCode(e) {
    const value = e.target.nextElementSibling.firstElementChild.innerText;

    document.addEventListener('copy', e => {
      e.preventDefault();
      e.clipboardData.setData('text/plain', value);
    },
      { once: true }
    );
    document.execCommand('copy');
    this.setState({ copied: true });

    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  render() {
    return (
      <React.Fragment>
        <button className="gatsby-highlight__copy" type="button" onClick={(e) => this.copyCode(e)}>{this.state.copied ? 'Copied' : 'Copy'}</button>
        <pre {...this.props} />
      </React.Fragment>
    )
  }
}
export default CopyButton;
