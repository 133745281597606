import React from 'react'
import Icon from '../parts/icon.js'

const ContentLink = (props) => {
  const otherSite = props.href.indexOf('http') !== -1 ? true : false;

  return (
    <a {...props} target={otherSite ? '_blank' : ''} rel={otherSite ? 'noopener noreferrer' : ''}>
      {props.children} {otherSite ? <Icon type="open_in_new" /> : ''}
    </a>
  )
}
export default ContentLink;
