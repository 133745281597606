import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import NoImg from '../parts/noImg.js'
import Icon from '../parts/icon.js'
import './card.scss'

const Card = ({ url }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: {order: DESC, fields: [frontmatter___date]}, limit: 1000) {
        edges {
          node {
            frontmatter {
              path
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let matchPost = data.allMdx.edges.find(post => post.node.frontmatter.path === url);

  if (matchPost) {
    const cardData = matchPost.node.frontmatter;

    return (
      <div className="card">
        <a href={url} className="card__link" target="_blank" rel="noopener noreferrer">
          <div className="card__content">
            <p className="card__title">{cardData.title}</p>
            <p className="card__text">
              <span className="card__button">続きを読む<Icon type="open_in_new" /></span>
            </p>
          </div>
          <figure className="card__thumb-area">
            {
              cardData.featuredImage !== null ? <Img fluid={cardData.featuredImage.childImageSharp.fluid} className="card__thumb" /> : <NoImg className="card__thumb" />
            }
          </figure>
        </a>
      </div>
    )
  }

  return (
    <a href={url}>{url}</a>
  )
}
export default Card;
