import React from 'react'

const Image = (props) => {

  return (
    <figure>
      <img src={props.src} alt={props.alt ? props.alt : ''} aria-hidden="true" />
      {
        props.alt ? (<span className="screen-reader-text">{props.alt}</span>) : ''
      }
    </figure>
  )
}
export default Image;
