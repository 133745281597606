import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Img from 'gatsby-image'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import Content from '../components/layout/content.js'
import Layout from '../components/layout/layout.js'
import Share from '../components/parts/share.js'
import SEO from '../components/parts/seo.js'
import ResentPost from '../components/parts/resentPost.js'
import NoImg from '../components/parts/noImg.js'
import Icon from '../components/parts/icon.js'
import TOC from '../components/shortcodes/toc.js'
import Card from '../components/shortcodes/card.js'
import Image from '../components/shortcodes/img.js'
import CopyButton from '../components/shortcodes/copyButton.js'
import ContentLink from '../components/shortcodes/contentLink.js'
import PostList from '../components/parts/postList.js'
import Ad from '../components/parts/ad.js'
import './single.scss'

const Single = ({ data, uri }) => {
  const shortcodes = {
    TOC,
    Card,
    Image,
    pre: CopyButton,
    a: ContentLink
  }

  const { mdx } = data
  const categories = data.site.siteMetadata.categories;
  const { frontmatter } = mdx
  let categoryList = [];
  if (frontmatter.categories.length > 0) {
    frontmatter.categories.forEach((item) => {
      let categoryMatch = categories.find(category => category.slug === item);
      if (categoryMatch) {
        categoryList.push(
          {
            slug: categoryMatch.slug,
            name: categoryMatch.name
          }
        )
      }
    })
  }

  let breadcrumb = [];

  if (categoryList.length > 0) {
    breadcrumb.push({
      url: `/category/${categoryList[0].slug}/`,
      name: categoryList[0].name
    });
  }

  breadcrumb.push({
    url: uri,
    name: frontmatter.title
  })

  return (
    <Layout breadcrumb={breadcrumb}>
      <SEO
        pageType="single"
        categories={categoryList}
        title={frontmatter.title}
        description={frontmatter.description || ''}
        pathname={frontmatter.path}
        image={frontmatter.featuredImage !== null ? frontmatter.featuredImage.childImageSharp.fluid.src : ''}
        datePublished={new Date(frontmatter.date).toISOString()}
        dateModified={frontmatter.modified > frontmatter.date ? new Date(frontmatter.modified).toISOString() : ''}
        article
      />
      <div className="article">
        <figure className="article__thumb-area">
          {
            frontmatter.featuredImage !== null ? <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} className="article__thumb" /> : <NoImg className="article__thumb" />
          }
        </figure>
        <h1 className="article__title">{frontmatter.title}</h1>
        <div className="article__meta">
          <p className="article__date">
            <Icon type="edit" className="article__icon" />
            <span className="screen-reader-text">投稿日</span>
            <time dateTime={frontmatter.date}>{frontmatter.date.replace(/-/g, '.')}</time>
          </p>
          {
            frontmatter.modified > frontmatter.date ? <p className="article__date">
              <Icon type="sync" className="article__icon" />
              <span className="screen-reader-text">更新日</span>
              <time dateTime={frontmatter.modified}>{frontmatter.modified.replace(/-/g, '.')}</time>
            </p> : ''
          }
          {
            categoryList.length > 0 ?
              <div className="article__categories">
                <Icon type="folder" className="article__icon" />
                <ul className="categories-list">
                  {
                    categoryList.map(category => (
                      <li key={category.slug} className="categories-list__item">
                        <Link to={`/category/${category.slug}/`} className="categories-list__link">{category.name}</Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              : ''
          }
        </div>
        <div className="article__content">
          <MDXProvider components={shortcodes}>
            <MDXRenderer headings={mdx.tableOfContents}>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
      <Share title={frontmatter.title} pathname={frontmatter.path} />
      <div className="content__item">
        <Ad slot="2035691114" />
      </div>
      {
        data.allMdx.edges.length > 0 ?
          <Content.Item>
            <h2 className="title title_main">関連記事</h2>
            <PostList posts={data.allMdx.edges} col="3" heading="3" />
          </Content.Item>
          : ''
      }
      <Content.Item className="content__item" section>
        <h2 className="title title_main">最新記事</h2>
        <ResentPost type="postlist" />
      </Content.Item>
    </Layout>
  )
}

export default Single;

export const pageQuery = graphql`
  query($path: String!, $category: String!) {
    site {
      siteMetadata {
        categories {
          name
          slug
        }
      }
    }
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      tableOfContents
      frontmatter {
        date
        modified
        path
        title
        description
        categories
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
              originalImg
            }
          }
        }
      }
    }
    allMdx(limit: 3, sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {categories: {eq: $category}, path: {ne: $path}}}) {
      edges {
        node {
          id
          frontmatter {
            date
            path
            title
            categories
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 678) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
